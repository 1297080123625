import { useKrakenMutation } from '@/components/GraphQLClient';

import { requestPrintedBillSchema } from '../validations';

import RequestPrintedBill from './RequestPrintedBill.graphql';

type RequestPrintedBillInput = {
  billingDocumentId: string;
  accountNumber: string;
};

export const useRequestPrintedBill = () => {
  return useKrakenMutation({
    mutation: (_: RequestPrintedBillInput) => RequestPrintedBill,
    validateFn(response) {
      return requestPrintedBillSchema.validate(response);
    },
  });
};
