import { Stack, Typography, useCoralBreakpoints } from '@krakentech/coral';

import { PAYMENT_FREQUENCY } from '@/consts/paymentFrequency';
import { usePaymentSchedulesContext } from '@/context';
import { cardinalNumberToOrdinalNumber } from '@/utils/formatters/number';
import { getPaymentFrequency } from '@/utils/paymentSchedule';

import BoxWithGreyBorder from '../../../../BoxWithGreyBorder';
import MutedText from '../../../../MutedText';
import PaymentFrequencyTooltip from '../PaymentFrequencyTooltip';

const PaymentFrequency = () => {
  const { isMinMd } = useCoralBreakpoints();
  const {
    currentActivePaymentSchedule: {
      isVariablePaymentAmount,
      paymentFrequencyMultiplier,
      paymentFrequency,
      paymentDay,
    },
  } = usePaymentSchedulesContext();

  const getPaymentFrequencyText = () => {
    if (isVariablePaymentAmount) {
      return 'When you get your bill';
    } else {
      return `Every ${getPaymentFrequency(
        paymentFrequency,
        paymentFrequencyMultiplier
      )}${
        paymentFrequency === PAYMENT_FREQUENCY.MONTHLY
          ? ` on the ${cardinalNumberToOrdinalNumber(paymentDay!)}`
          : ''
      }`;
    }
  };

  return (
    <BoxWithGreyBorder>
      <Stack
        direction="vertical"
        gap="xs"
        alignItems="center"
        md={{
          alignItems: 'flex-start',
          direction: 'vertical',
          gap: 'xs',
        }}
      >
        <Stack gap="xs">
          <MutedText variant="h3" textAlign={isMinMd ? 'left' : 'center'}>
            You pay:
          </MutedText>
          <PaymentFrequencyTooltip
            isVariablePaymentAmount={isVariablePaymentAmount!}
            paymentFrequency={paymentFrequency!}
            paymentFrequencyMultiplier={paymentFrequencyMultiplier!}
          />
        </Stack>
        <Typography textAlign={isMinMd ? 'left' : 'center'}>
          {getPaymentFrequencyText()}
        </Typography>
      </Stack>
    </BoxWithGreyBorder>
  );
};

export default PaymentFrequency;
