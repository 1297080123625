import { Dialog, Stack, Button, Typography, Alert } from '@krakentech/coral';
import { FormikTextField } from '@krakentech/coral-formik';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import 'yup-phone-lite';

import { MARKETING_PREFERENCE_OPTIONS } from '@/consts/marketingPreferenceOptions';
import { useCommsPreferencesContext } from '@/context';
import { useUpdateUserMobileNumber } from '@/hooks/accounts/useUpdateUserMobileNumber';

import ButtonLoadingLabel from '../../ButtonLoadingLabel';

const MobileNumberFormDialog = () => {
  const { setFieldValue } = useFormikContext();
  const {
    showMobileFormDialog,
    setShowMobileFormDialog,
    setMobileFormDialogToggleOrigin,
  } = useCommsPreferencesContext();

  const toggleSmsSwitchOn = () => {
    setFieldValue(MARKETING_PREFERENCE_OPTIONS.SMS, true);
  };

  const closeDialog = () => {
    setShowMobileFormDialog(false);
    setMobileFormDialogToggleOrigin(null);
    reset();
  };

  const { isLoading, isError, mutate, reset } = useUpdateUserMobileNumber({
    closeDialog,
    toggleSmsSwitchOn,
  });

  return (
    <Dialog
      open={showMobileFormDialog}
      onClose={closeDialog}
      ariaLabelledBy="mobileFormToggle"
    >
      <Stack direction="vertical" gap="md" component="section">
        <Typography variant="h1">Add your mobile number</Typography>
        <Formik
          initialValues={{ mobile: '' }}
          onSubmit={(values) => {
            mutate({
              input: {
                mobile: values.mobile,
              },
            });
          }}
          validationSchema={Yup.object().shape({
            mobile: Yup.string()
              .phone('GB', 'Please enter a valid number')
              .required('Please enter a mobile number'),
          })}
        >
          <Form>
            <Stack direction="vertical" gap="md">
              <FormikTextField name="mobile" label="Mobile number" />

              {isError && (
                <Alert severity="error">
                  There was a problem trying to add your number, please try
                  again
                </Alert>
              )}

              <Stack justifyContent="flex-end">
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={closeDialog}
                  type="button"
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  loading={isLoading}
                  disabled={isLoading}
                  loadingLabel={<ButtonLoadingLabel>Save</ButtonLoadingLabel>}
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </Form>
        </Formik>
      </Stack>
    </Dialog>
  );
};

export default MobileNumberFormDialog;
