import { FormEventHandler, useState } from 'react';
import { useRouter } from 'next/router';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { Alert, Card, Stack, Typography } from '@krakentech/coral';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import type { StripeError } from '@stripe/stripe-js';

import FormSubmitButton from '@/components/forms/FormSubmitButton';
import MutedText from '@/components/MutedText';
import { useOnlinePaymentContext } from '@/context/OnlinePaymentContext';
import {
  formatCurrency,
  formatCurrencyWithCreditOrDebitIndicator,
} from '@/utils/formatters/currency';
import { INTERNAL_PATHS } from '@/utils/urls';

import StripePaymentForm from '../StripePaymentForm';

const defaultStripeErrorMessage =
  'Your payment did not go through. Check your details are correct and you have sufficient funds then try again. If not, try using another card.';

const OnlinePaymentForm = ({ clientSecret }: { clientSecret?: string }) => {
  const [stripeFormLoading, setStripeFormLoading] = useState(false);
  const [stripeErrorMessage, setStripeErrorMessage] = useState<string>('');
  const stripe = useStripe();
  const elements = useElements();
  const { push } = useRouter();
  const { balance, paymentAmount } = useOnlinePaymentContext();

  const handleSubmitStripeForm: FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();

    setStripeFormLoading(true);

    // Send away the Stripe error alert if it's currently being displayed
    setStripeErrorMessage('');

    if (!stripe || !elements || !clientSecret) {
      return;
    }

    try {
      const { paymentIntent } =
        await stripe.retrievePaymentIntent(clientSecret);

      if (!paymentIntent) {
        throw new Error('Unable to retrieve payment intent.');
      }

      const { error: paymentError } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      });

      if (paymentError) {
        throw paymentError;
      }

      setStripeFormLoading(false);

      // If there's no payment error, then the payment has been successful
      push(INTERNAL_PATHS.MAKE_ONLINE_PAYMENT[2].path);
    } catch (error) {
      const err = error as Error | StripeError;

      if ('type' in err) {
        setStripeErrorMessage(
          err.type === 'card_error' || err.type === 'validation_error'
            ? (err.message as string)
            : defaultStripeErrorMessage
        );
      } else {
        setStripeErrorMessage(err.message);
      }

      setStripeFormLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmitStripeForm}>
      <Stack gap="md" md={{ gap: 'lg' }} direction="vertical">
        <Card>
          <Stack direction="vertical" gap="md">
            <Typography variant="h2">Card details</Typography>

            <StripePaymentForm />

            {stripeErrorMessage && (
              <Alert severity="error" icon={false}>
                {stripeErrorMessage}
              </Alert>
            )}
          </Stack>
        </Card>

        <Stack direction="vertical" gap="md">
          <MutedText textAlign="center">
            Balance after payment:{' '}
            {formatCurrencyWithCreditOrDebitIndicator(balance! + paymentAmount)}
          </MutedText>

          <FormSubmitButton loading={stripeFormLoading} icon={faLock}>
            Pay {formatCurrency(paymentAmount)}
          </FormSubmitButton>
        </Stack>
      </Stack>
    </form>
  );
};

export default OnlinePaymentForm;
