import { PropsWithChildren } from 'react';
import {
  Alert as CoralAlert,
  AlertSeverity,
  useCoralTheme,
} from '@krakentech/coral';
import {
  IconDismiss,
  IconError,
  IconInfo,
  IconQuestion,
  IconSuccess,
  IconWarning,
} from '@krakentech/icons';

const Alert = ({
  severity,
  removeIcon = false,
  onClose,
  children,
}: PropsWithChildren<{
  severity: AlertSeverity;
  removeIcon?: boolean;
  onClose?: () => void;
}>) => {
  const {
    color: { action },
  } = useCoralTheme();

  return (
    <CoralAlert
      severity={severity}
      {...(onClose && { onClose })}
      icon={removeIcon ? false : <SeverityIcon severity={severity} />}
      {...(onClose && {
        closeIcon: <IconDismiss color={action[severity]} size={16} />,
      })}
    >
      {children}
    </CoralAlert>
  );
};

function SeverityIcon({ severity }: { severity: AlertSeverity }) {
  const {
    color: { action },
  } = useCoralTheme();

  const colorAndSize = {
    color: action[severity],
    size: 20,
  };

  switch (severity) {
    case 'info':
      return <IconInfo {...colorAndSize} />;
    case 'success':
      return <IconSuccess {...colorAndSize} />;
    case 'warning':
      return <IconWarning {...colorAndSize} />;
    case 'error':
      return <IconError {...colorAndSize} />;
    default:
      return <IconQuestion {...colorAndSize} />;
  }
}

export default Alert;
