import { faArrowRightFromBracket } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Stack, Loader, toast } from '@krakentech/coral';

import { useForceReauthentication } from '@/hooks/auth/useForceReauthentication';

const LogoutButton = ({ isMobile }: { isMobile: boolean }) => {
  const displayError = () => {
    toast('There was a problem logging you out, please try again', {
      type: 'error',
    });
  };
  const { mutate, isLoading } = useForceReauthentication({ displayError });

  const logout = () => {
    mutate({
      input: {
        includeThirdParties: true,
      },
    });
  };

  return (
    <>
      <Button
        color="tertiary"
        size={isMobile ? 'medium' : 'small'}
        title="Logout"
        onClick={logout}
        disabled={isLoading}
      >
        {isMobile ? (
          <>
            {isLoading ? (
              <Loader size={16} color="text" />
            ) : (
              <FontAwesomeIcon icon={faArrowRightFromBracket} />
            )}
          </>
        ) : (
          <Stack gap="sm" alignItems="center">
            {isLoading ? (
              <Loader size={16} color="text" />
            ) : (
              <FontAwesomeIcon icon={faArrowRightFromBracket} />
            )}
            Logout
          </Stack>
        )}
      </Button>
    </>
  );
};

export default LogoutButton;
