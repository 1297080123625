import { ReactNode } from 'react';
import Link from 'next/link';
import { Button, Stack, Typography, useCoralTheme } from '@krakentech/coral';
import { IconSuccess } from '@krakentech/icons';

import FormContainer from '../forms/FormContainer';
import {
  Body1Skeleton,
  CircularSkeleton,
  FullWidthButtonSkeleton,
  H1Skeleton,
} from '../Skeletons';

const SuccessPage = ({
  title,
  linkHref,
  linkTitle,
  Explanation,
  isLoading = false,
  displayLink = true,
}: {
  title: string;
  linkHref: string;
  linkTitle: string;
  Explanation?: ReactNode;
  isLoading?: boolean;
  displayLink?: boolean;
}) => {
  const {
    color: {
      action: { success },
    },
  } = useCoralTheme();

  return (
    <FormContainer>
      {isLoading ? (
        <Stack direction="vertical" gap="lg">
          <Stack direction="vertical" gap="md" alignItems="center">
            <CircularSkeleton width="6.3rem" height="6.3rem" />

            <H1Skeleton />

            <Body1Skeleton width={300} />
            <Body1Skeleton width={300} />

            <FullWidthButtonSkeleton />
          </Stack>
        </Stack>
      ) : (
        <Stack direction="vertical" gap="lg">
          <Stack direction="vertical" gap="md" alignItems="center">
            <IconSuccess color={success} size={63} />

            <Stack direction="vertical" gap="sm" alignItems="center">
              <Typography variant="h1" textAlign="center">
                {title}
              </Typography>

              {Explanation && Explanation}
            </Stack>
          </Stack>
          {displayLink && (
            <Link href={linkHref} passHref legacyBehavior>
              <Button size="medium" fullWidth>
                {linkTitle}
              </Button>
            </Link>
          )}
        </Stack>
      )}
    </FormContainer>
  );
};

export default SuccessPage;
