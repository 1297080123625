import { Card, Stack, Typography, Button } from '@krakentech/coral';
import { FormikSwitch } from '@krakentech/coral-formik';
import { useFormikContext } from 'formik';

import { MARKETING_PREFERENCE_OPTIONS } from '@/consts/marketingPreferenceOptions';
import { MOBILE_FORM_DIALOG_TOGGLE_ORIGIN } from '@/consts/mobileFormDialogToggleOrigin';
import { useCommsPreferencesContext } from '@/context';
import { useUpdateCommsPreferences } from '@/hooks/accounts/useUpdateCommsPreferences';
import { useMovingOutStatus } from '@/hooks/moveHome/useMovingOutStatus';
import { SwitchOverride } from '@/styles/Overrides.styled';

import MutedText from '../../MutedText';
import MobileNumberFormDialog from '../MobileNumberFormDialog';

const SmsPreferencesSection = () => {
  const {
    mutationLoading,
    setMutationLoading,
    setShowMobileFormDialog,
    setMobileFormDialogToggleOrigin,
    mobile,
  } = useCommsPreferencesContext();
  const { mutate } = useUpdateCommsPreferences();
  const { setFieldValue } = useFormikContext();
  const { hasMovedOut } = useMovingOutStatus();

  const submitUpdateCommsPreference = (fieldValue: boolean) => {
    if (!mobile) {
      setShowMobileFormDialog(true);
      setMobileFormDialogToggleOrigin(MOBILE_FORM_DIALOG_TOGGLE_ORIGIN.SWITCH);
      setFieldValue(MARKETING_PREFERENCE_OPTIONS.SMS, false);
    } else {
      // Disable all the inputs
      setMutationLoading(true);

      mutate({
        input: {
          [MARKETING_PREFERENCE_OPTIONS.SMS]: fieldValue,
        },
      });
    }
  };

  return (
    <>
      <Card>
        <Stack direction="vertical" gap="md">
          <Stack direction="vertical" gap="xs">
            <Typography variant="h2">Notifications via SMS</Typography>
            <Typography variant="body2" color="dark">
              Choose if we send important notifications to you via SMS
            </Typography>
          </Stack>

          <Stack direction="vertical" gap="xxs">
            <SwitchOverride>
              <FormikSwitch
                name={MARKETING_PREFERENCE_OPTIONS.SMS}
                onChange={(_, fieldValue) =>
                  submitUpdateCommsPreference(fieldValue)
                }
                disabled={hasMovedOut || mutationLoading}
              >
                <Stack alignItems="center">
                  <FormikSwitch.Label label="SMS messages" />
                  <FormikSwitch.Input />
                </Stack>
              </FormikSwitch>
            </SwitchOverride>
            {mobile ? (
              <MutedText>We&apos;ll send a message to {mobile}</MutedText>
            ) : (
              <>
                <Stack justifyContent="flex-start" flexWrap="wrap" gap="xxs">
                  <MutedText>
                    We can&apos;t send you any messages as you don&apos;t have a
                    mobile number on your account.
                  </MutedText>{' '}
                  <Button
                    variant="text"
                    id="mobileFormToggle"
                    onClick={() => {
                      setShowMobileFormDialog(true);
                      setMobileFormDialogToggleOrigin(
                        MOBILE_FORM_DIALOG_TOGGLE_ORIGIN.BUTTON
                      );
                    }}
                  >
                    Please add one now
                  </Button>
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
      </Card>

      <MobileNumberFormDialog />
    </>
  );
};

export default SmsPreferencesSection;
