import { useRef, useState } from 'react';
import { Button, useCoralTheme, Visibility } from '@krakentech/coral';
import { FormikDatePicker } from '@krakentech/coral-formik';
import { IconCalendar } from '@krakentech/icons';

import { FormFieldWithButton } from '@/components';

import { CalendarPickerProps } from './index.types';

const CalendarPicker = ({
  inputName,
  inputLabel,
  inputPlaceholder = 'DD / MM / YYYY',
  mutedText,
}: CalendarPickerProps) => {
  const buttonRef = useRef(null);
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);

  const {
    color: {
      base: { main: buttonIconColour },
    },
  } = useCoralTheme();

  const handleOnClickOutside = (event: { target: null }) => {
    if (event.target !== buttonRef.current) {
      setIsOpenDatePicker(false);
    }
  };

  const ariaLabel = isOpenDatePicker ? 'hide calendar' : 'show calendar';
  const btnLabel = `${isOpenDatePicker ? 'Hide' : 'Show'} calendar`;

  return (
    <FormFieldWithButton
      input={
        <FormikDatePicker
          name={inputName}
          label={inputLabel}
          placeholderText={inputPlaceholder}
          open={isOpenDatePicker}
          onSelect={() => setIsOpenDatePicker(false)}
          fullWidth
          onClickOutside={handleOnClickOutside}
          popperPlacement="bottom-end"
        />
      }
      button={
        <Button
          ref={buttonRef}
          color="primary"
          onClick={() => setIsOpenDatePicker(!isOpenDatePicker)}
          startIcon={<IconCalendar color={buttonIconColour} size={16} />}
          fullWidth
          aria-label={ariaLabel}
          size="large"
          md={{
            size: 'medium',
          }}
        >
          <Visibility display="none" md={{ display: 'block' }}>
            {btnLabel}
          </Visibility>
        </Button>
      }
      mutedText={mutedText}
    />
  );
};

export default CalendarPicker;
