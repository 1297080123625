import { getYear } from 'date-fns';

import { METER_READING_SOURCES } from '@/consts/meterReadingSources';
import { addHyphensIntoSortCode } from '@/utils/formatters/sortCode';

export const accountNumber = 'A-12345678';

// Name and personal details
export const title = 'Mr';
export const givenName = 'Richard';
export const middleName = 'Mark';
export const familyName = 'Hammond';
export const fullName = `${givenName} ${familyName}`;
export const preferredName = givenName;
export const pronouns = 'he/him';

// Second account user
export const secondGivenName = 'James';
export const secondFamilyName = 'May';
export const secondFullName = `${secondGivenName} ${secondFamilyName}`;

// Account user names
export const oneAccountUserName = [{ givenName, familyName, id: '1' }];
export const twoAccountUserNames = [
  ...oneAccountUserName,
  { givenName: secondGivenName, familyName: secondFamilyName, id: '1' },
];

// Date of birth
export const yearOfBirth = '1995';
export const monthOfBirth = '03';
export const dayOfBirth = '01';
export const dateOfBirth = `${yearOfBirth}-${monthOfBirth}-${dayOfBirth}`;

// Contact details
export const email = 'richardhammond@grandtour.com';
export const mobile = '+447843900123';
export const landline = '+441494775955';

// Supply address
export const supplyAddressLine1 = '1 Test Street';
export const supplyAddressLine2 = 'Penistone';
export const supplyAddressLine3 = 'Sheffield';
export const supplyAddressPostcode = 'KT2 5FF';
export const splitSupplyAddress = [
  supplyAddressLine1,
  supplyAddressLine2,
  supplyAddressLine3,
  '',
  '',
];
export const supplyAddress = `${supplyAddressLine1}, ${supplyAddressLine2}, ${supplyAddressLine3}, ${supplyAddressPostcode}`;

// Billing address
export const billingAddressLine1 = 'House of mocked data';
export const billingAddressLine2 = '2 More Test Avenue';
export const billingAddressLine3 = 'Chesham';
export const billingAddressLine4 = 'Buckinghamshire';
export const billingAddressPostcode = 'HP5 1SP';
export const splitBillingAddress = [
  billingAddressLine1,
  billingAddressLine2,
  billingAddressLine3,
  billingAddressLine4,
  '',
];
export const billingAddress = `${billingAddressLine1}, ${billingAddressLine2}, ${billingAddressLine3}, ${billingAddressLine4}, ${billingAddressPostcode}`;

// Balance
export const positiveBalance = 3000;
export const negativeBalance = -3000;

// Bills
export const billType = 'STATEMENT';

// Meter details
export const meterId = '100';
export const meterSerialNumber = 'METER_123456';
export const numberOfDigits = 5;
export const previousReadingValueCubicMetres = 1234;
export const previousReadingDate = '2022-12-25';
export const previousReadingSource = METER_READING_SOURCES.CUSTOMER;
export const defaultValueCubicMetres = 0;

// PSR
export const psrDescription = 'Mock description';
export const extraInfoField = 'name';
export const internalCode = 'mock_internal_code';

// Direct Debit
export const accountHolder = 'Acc Holder';
export const sortCode = '123456';
export const hyphenatedSortCode = addHyphensIntoSortCode(sortCode);
export const maskedAccountIdentifier = '*****123';

// Payment schedule
export const currentPaymentScheduleEndDate = `${getYear(new Date()) + 1}-01-01`;
export const paymentSchedulePaymentAmount = 2600;
export const paymentDay = 1;

// Payment forecast
export const nextPaymentDate = '2024-01-01';

// Proposed payment schedule
export const proposedPaymentScheduleStartDate = '2024-01-15';
export const proposedPaymentScheduleAmount = 3030;
export const firstInstalmentAmount = 3131;
export const firstInstalmentDate = proposedPaymentScheduleStartDate;
export const lastInstalmentDate = '2024-10-15';

//Generic error message
export const genericErrorMessage =
  'There was a problem trying to create your account. Please wait a few minutes and try again';

export const mobileErrorMessage =
  'The phone number you have provided is not a valid mobile number';

export const landlineErrorMessage =
  'The phone number you have provided is not a valid landline number';

export const mockHelpLink = 'https://www.kraken-help.com';
