import { FC } from 'react';
import { Container, Typography, Stack } from '@krakentech/coral';

import { H1Skeleton } from '@/components/Skeletons';
import { RelativelyPositionedDiv } from '@/styles/Utils.styled';


import AnimatedAlert from '../../AnimatedAlert';

import { ParentPageLayoutProps } from './index.types';

const ParentPageLayout: FC<ParentPageLayoutProps> = ({
  pageHeading,
  alertStatus,
  alertMessage,
  handleAlertClose,
  isLoading = false,
  children,
}) => (
  <Container
    component="section"
    marginX="auto"
    maxWidth="lg"
    marginY="md"
    paddingX="md"
    md={{
      marginY: 'lg',
      paddingX: 'lg',
    }}
  >
    <Stack
      direction="vertical"
      gap="md"
      md={{
        gap: 'lg',
      }}
    >
      <RelativelyPositionedDiv>
        {handleAlertClose && (
          <AnimatedAlert
            alertStatus={alertStatus}
            alertMessage={alertMessage}
            handleAlertClose={handleAlertClose}
          />
        )}
        <Typography variant="h1">
          {isLoading ? <H1Skeleton /> : pageHeading}
        </Typography>
      </RelativelyPositionedDiv>

      {children}
    </Stack>
  </Container>
);

export default ParentPageLayout;
