import { ReactNode } from 'react';
import { Alert, Typography, useCoralTheme } from '@krakentech/coral';
import { IconInfo } from '@krakentech/icons';

const ComingSoonBanner = ({ children }: { children: ReactNode }) => {
  const {
    color: {
      action: { info },
    },
  } = useCoralTheme();

  return (
    <Alert severity="info" icon={<IconInfo color={info} size={20} />}>
      <Typography>
        <strong>Coming soon: </strong>
        {children}
      </Typography>
    </Alert>
  );
};

export default ComingSoonBanner;
