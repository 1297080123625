import { Alert, Typography } from '@krakentech/coral';

import { ErrorLink } from '@/components';

const NotEnoughInfoAlert = () => (
  <Alert severity="error">
    <Typography>
      Sorry, we don&apos;t have enough information on your account to register
      online - please{' '}
      <ErrorLink href={process.env.NEXT_PUBLIC_HELP_LINK || ''}>
        get in touch
      </ErrorLink>{' '}
      and our team will help you get access to your online account.
    </Typography>
  </Alert>
);

export default NotEnoughInfoAlert;
