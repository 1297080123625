import { useState } from 'react';
import { Stack, Card, Typography, Link } from '@krakentech/coral';
import { FormikTextField } from '@krakentech/coral-formik';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';

import {
  Alert,
  DirectDebitGuaranteeDialog,
  FormSubmitButton,
} from '@/components';
import { BankDetailsFormValues } from '@/types/directDebit';
import { addHyphensIntoSortCode } from '@/utils/formatters/sortCode';

import MutedText from '../../MutedText';

type BankDetailsFormContentProps = {
  isMutationLoading: boolean;
  isMutationError: boolean;
};

type BankDetailsFormProps = BankDetailsFormContentProps & {
  handleSubmit: (values: any) => void;
};

const BankDetailsForm = ({
  handleSubmit,
  isMutationLoading,
  isMutationError,
}: BankDetailsFormProps) => {
  return (
    <Formik
      initialValues={{
        accountHolder: '',
        accountNumber: '',
        branchCode: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        accountHolder: Yup.string().required(
          "Please enter the account holder's name"
        ),
        accountNumber: Yup.string()
          .required('Please enter a valid account number')
          .length(8, 'Please enter a valid account number'),
        branchCode: Yup.string()
          .required('Please enter a valid sort code')
          .matches(
            // match any 6 digits separated by hyphens at 2 digit intervals, e.g. 12-34-56
            /^\d{2}-\d{2}-\d{2}$/,
            'Please enter a valid sort code'
          ),
      })}
    >
      <Form>
        <BankDetailsFormContent
          isMutationLoading={isMutationLoading}
          isMutationError={isMutationError}
        />
      </Form>
    </Formik>
  );
};

const BankDetailsFormContent = ({
  isMutationError,
  isMutationLoading,
}: BankDetailsFormContentProps) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const { setFieldValue, values } = useFormikContext<BankDetailsFormValues>();

  const hyphenateValue = (value: string) => value + '-';

  const handleDialogToggleClick = () => setIsDialogVisible(true);

  const hyphenateSortCodeOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;

    /* Add the first hyphen after 2 characters, so we have 3 total characters.
        Then add the second hyphen after 2 more characters, i.e. when length is 5. */
    if (value.length === 2 || value.length === 5) {
      const hyphenatedValue = hyphenateValue(value);
      setFieldValue('branchCode', hyphenatedValue);
    }
  };

  const hyphenateSortCodeOnBlur = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;

    const hyphenatedValue = addHyphensIntoSortCode(value);
    setFieldValue('branchCode', hyphenatedValue);
  };

  return (
    <Stack
      direction="vertical"
      gap="md"
      md={{
        gap: 'lg',
        direction: 'vertical',
      }}
    >
      <Card>
        <Stack
          direction="vertical"
          gap="sm"
          md={{
            gap: 'md',
            direction: 'vertical',
          }}
        >
          <Typography variant="h2">Bank details</Typography>
          <FormikTextField label="Account holder's name" name="accountHolder" />
          <Stack direction="vertical" gap="xs">
            <FormikTextField
              label="Account number"
              name="accountNumber"
              inputProps={{ inputMode: 'numeric', maxLength: 8 }}
              type="text"
            />
            <MutedText>Please enter 8 digits</MutedText>
          </Stack>
          <Stack direction="vertical" gap="xs">
            <FormikTextField
              label="Sort code"
              name="branchCode"
              inputProps={{ inputMode: 'numeric', maxLength: 8 }}
              type="text"
              onChange={hyphenateSortCodeOnChange}
              onBlur={hyphenateSortCodeOnBlur}
            />
            <MutedText>
              Please enter the 6 digits - no hyphens or spaces are needed
            </MutedText>
          </Stack>
          <Typography>
            Your payments are protected by the{' '}
            <Link onClick={handleDialogToggleClick}>
              Direct Debit guarantee
            </Link>
          </Typography>

          {isMutationError ? (
            <Alert severity="error">
              <Typography>
                Uh oh, there seems to be a problem! Please make sure your bank
                details are correct then try again. If the problem persists then{' '}
                <Link href={process.env.NEXT_PUBLIC_HELP_LINK}>contact us</Link>
              </Typography>
            </Alert>
          ) : null}
        </Stack>
      </Card>

      <DirectDebitGuaranteeDialog
        isOpen={isDialogVisible}
        closeDialog={() => setIsDialogVisible(false)}
      />
      <FormSubmitButton
        loading={isMutationLoading}
        disabled={
          !values.accountHolder || !values.accountNumber || !values.branchCode
        }
      >
        Set up Direct Debit
      </FormSubmitButton>
    </Stack>
  );
};

export default BankDetailsForm;
