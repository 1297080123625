import { useEffect } from 'react';
import { Card, Typography, Stack } from '@krakentech/coral';
import { Formik, Form, Field, useFormikContext } from 'formik';

import {
  Body2Skeleton,
  EstimatedPaymentsCard,
  FormContainer,
  FormSubmitButton,
  FullWidthButtonSkeleton,
  H4Skeleton,
  PageError,
  PaymentDayField,
  RadioCard,
  RadioCardSkeleton,
  TextFieldSkeleton,
} from '@/components';
import { DIRECT_DEBIT_SCHEDULE_CHOICES } from '@/consts/paymentFrequency';
import { PAYMENT_FREQUENCY_FIELD_OPTIONS } from '@/consts/paymentFrequency';
import { OptionalDate } from '@/context/DirectDebitContext/index.types';
import { useBalance } from '@/hooks/accounts/useBalance';
import { useProposedDirectDebitPaymentSchedule } from '@/hooks/billsAndPayments/useProposedDirectDebitPaymentSchedule';
import { useHasActiveWaterMeter } from '@/hooks/usage/useHasActiveWaterMeter';
import { PaymentFrequencyFormValues } from '@/types/directDebit';

type PaymentFrequencyFormContentProps = {
  setFirstPossibleVariableSchedulePaymentDate: (date: OptionalDate) => void;
  setHasActiveWaterMeter: (hasActiveWaterMeter: boolean) => void;
};

type PaymentFrequencyFormProps = PaymentFrequencyFormContentProps & {
  handleSubmit: (values: PaymentFrequencyFormValues) => void;
  initialPaymentFrequency: PAYMENT_FREQUENCY_FIELD_OPTIONS | undefined;
  initialPaymentDay: { label: string; value: number } | undefined;
};

const PaymentFrequencyFormContent = ({
  setFirstPossibleVariableSchedulePaymentDate,
  setHasActiveWaterMeter,
}: PaymentFrequencyFormContentProps) => {
  const { values } = useFormikContext<PaymentFrequencyFormValues>();
  const {
    isError: isErrorProposedPaymentSchedule,
    refetch,
    data,
  } = useProposedDirectDebitPaymentSchedule({
    scheduleType:
      typeof values.paymentFrequency !== 'undefined' &&
      values.paymentFrequency === PAYMENT_FREQUENCY_FIELD_OPTIONS.VARIABLE
        ? DIRECT_DEBIT_SCHEDULE_CHOICES.PAY_ON_RECEIPT_OF_BILL
        : DIRECT_DEBIT_SCHEDULE_CHOICES.MONTHLY,
  });
  const {
    data: activeWaterMeterCount,
    isError: isErrorActiveWaterMeter,
    isLoading: isLoadingActiveWaterMeter,
  } = useHasActiveWaterMeter();
  const {
    data: balance,
    isLoading: isLoadingBalance,
    isError: isErrorBalance,
  } = useBalance();

  // Display the estimated payments card if the user is on a variable schedule or if they are on a monthly schedule and have selected a payment day. Or in other words, only display the estimated payments card if the above form fields have been filled in
  const displayEstimatedPaymentsCard =
    values.paymentFrequency === PAYMENT_FREQUENCY_FIELD_OPTIONS.VARIABLE ||
    (values.paymentFrequency === PAYMENT_FREQUENCY_FIELD_OPTIONS.MONTHLY &&
      values.paymentDay?.value);

  // Disable the form submit button if the user is on a variable schedule and the proposed payment schedule is in error, or if the estimated payments card is not displayed. If the esimated payments card is not displayed, it means the user has not filled in the form fields
  const disableFormSubmitButton =
    (isErrorProposedPaymentSchedule &&
      values.paymentFrequency !== PAYMENT_FREQUENCY_FIELD_OPTIONS.VARIABLE) ||
    !displayEstimatedPaymentsCard;

  // Save the firstPossiblePaymentDate to context if the user is on a variable schedule, so we can send it over the mutation later
  useEffect(() => {
    if (values.paymentFrequency === PAYMENT_FREQUENCY_FIELD_OPTIONS.VARIABLE) {
      setFirstPossibleVariableSchedulePaymentDate(
        data?.firstPossibleCollectionDate
      );
    }
  }, [
    values.paymentFrequency,
    data,
    setFirstPossibleVariableSchedulePaymentDate,
  ]);

  useEffect(() => {
    setHasActiveWaterMeter(!!activeWaterMeterCount);
  });

  // Block the user from setting up a fixed payment schedule if they're unmeasured and aren't in debit, as the mutation would error in this case
  const blockFixedPaymentSchedule = !activeWaterMeterCount && balance >= 0;

  if (isLoadingBalance || isLoadingActiveWaterMeter) {
    return (
      <Stack gap="md" md={{ gap: 'lg' }} direction="vertical">
        <Card>
          <Stack direction="vertical" gap="md">
            <H4Skeleton />
            <RadioCardSkeleton />
            <RadioCardSkeleton />
          </Stack>
        </Card>

        <Card>
          <Stack direction="vertical" gap="md">
            <H4Skeleton />
            <Stack direction="vertical" gap="sm">
              <TextFieldSkeleton />

              <Body2Skeleton />
            </Stack>
          </Stack>
        </Card>

        <FullWidthButtonSkeleton />
      </Stack>
    );
  }

  if (isErrorActiveWaterMeter || isErrorBalance) {
    return <PageError />;
  }

  return (
    <Stack gap="md" md={{ gap: 'lg' }} direction="vertical">
      <Card>
        <Stack direction="vertical" gap="md">
          <Typography variant="h2">How often do you want to pay?</Typography>

          <Field
            name="paymentFrequency"
            label="When you get your bill"
            explanation={`Every ${
              activeWaterMeterCount
                ? process.env.NEXT_PUBLIC_MEASURED_BILLING_PERIOD
                : process.env.NEXT_PUBLIC_UNMEASURED_BILLING_PERIOD
            } months, we'll collect any balance owed in full a few days after your bill has been issued`}
            component={RadioCard}
            value={PAYMENT_FREQUENCY_FIELD_OPTIONS.VARIABLE}
            dataTestId="variableScheduleField"
            onChange={refetch}
          />
          <Field
            name="paymentFrequency"
            label="Monthly"
            explanation={
              blockFixedPaymentSchedule
                ? "You can't set up monthly payments right now. Please come back after you receive your next bill"
                : 'Pay into your account with monthly regular payments'
            }
            component={RadioCard}
            value={PAYMENT_FREQUENCY_FIELD_OPTIONS.MONTHLY}
            dataTestId="monthlyScheduleField"
            onChange={refetch}
            disabled={blockFixedPaymentSchedule}
          />
        </Stack>
      </Card>

      {values.paymentFrequency === PAYMENT_FREQUENCY_FIELD_OPTIONS.MONTHLY && (
        <Card>
          <Stack direction="vertical" gap="md">
            <Typography variant="h2">Choose a payment date</Typography>

            <PaymentDayField />
          </Stack>
        </Card>
      )}

      {displayEstimatedPaymentsCard && <EstimatedPaymentsCard />}

      <FormSubmitButton loading={false} disabled={disableFormSubmitButton}>
        Add bank details
      </FormSubmitButton>
    </Stack>
  );
};

const PaymentFrequencyForm = ({
  setHasActiveWaterMeter,
  setFirstPossibleVariableSchedulePaymentDate,
  handleSubmit,
  initialPaymentFrequency,
  initialPaymentDay,
}: PaymentFrequencyFormProps) => {
  return (
    <FormContainer>
      <Formik
        initialValues={{
          paymentFrequency: initialPaymentFrequency,
          paymentDay: initialPaymentDay,
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <PaymentFrequencyFormContent
            setFirstPossibleVariableSchedulePaymentDate={
              setFirstPossibleVariableSchedulePaymentDate
            }
            setHasActiveWaterMeter={setHasActiveWaterMeter}
          />
        </Form>
      </Formik>
    </FormContainer>
  );
};

export default PaymentFrequencyForm;
