export const cookies = [
  {
    disabled: false,
    isCheckedByDefault: true,
    label: 'Analytics & customisation',
    id: 'analytics',
    tooltip:
      'These ones help us understand how customers are using our site. They help us build better features and tailor our content for different people.',
  },
];
