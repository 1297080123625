import styled, { css } from 'styled-components';

export const StyledBackLink = styled.a`
  ${({ theme }) => css`
    color: ${theme.color.primary.main};

    &:focus-visible {
      outline-width: ${theme.borderWidth};
      outline-style: solid;
      outline-color: ${theme.color.tertiary.main};
      outline-offset: ${theme.spacing.xxs};
    }
  `}
`;
