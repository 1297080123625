import { Alert, Container, useCoralTheme } from '@krakentech/coral';
import { IconDismiss } from '@krakentech/icons';

import { useGlobalMessage } from '../Providers/GlobalMessage';

const GlobalMessage = () => {
  const {
    color: { action },
  } = useCoralTheme();

  const {
    globalMessage: { message, messageSeverity = 'info' },
    setGlobalMessage,
  } = useGlobalMessage();

  return message ? (
    <Container marginY="lg" marginX="auto" maxWidth="lg" paddingX="md">
      <Alert
        severity={messageSeverity}
        onClose={() => setGlobalMessage({ message: null })}
        icon={false}
        closeIcon={<IconDismiss color={action[messageSeverity]} size={16} />}
      >
        {message}
      </Alert>
    </Container>
  ) : null;
};

export default GlobalMessage;
