import { Stack, Typography } from '@krakentech/coral';

import SetUpDirectDebitCta from '@/components/billingAndPayment/PaymentPlanCard/SetUpDirectDebitCta';
import BoxWithGreyBorder from '@/components/BoxWithGreyBorder';
import { useCurrentActiveDirectDebitInstruction } from '@/hooks/billsAndPayments/useCurrentActiveDirectDebitInstruction';
import { addHyphensIntoSortCode } from '@/utils/formatters/sortCode';


const BankDetailsCard = () => {
  const { data } = useCurrentActiveDirectDebitInstruction();

  if (typeof data === 'undefined' || !data?.node?.id) {
    return <SetUpDirectDebitCta />;
  } else {
    const { accountHolder, sortCode, maskedAccountIdentifier } = data.node;

    return (
      <BoxWithGreyBorder testId="bankDetailsCard" darkBackground>
        <Stack
          direction="vertical"
          gap="sm"
          alignItems="center"
          md={{
            direction: 'horizontal',
            gap: 'md',
          }}
        >
          <Typography>{accountHolder}</Typography>

          <Stack gap="md">
            <Typography variant="body2">{maskedAccountIdentifier}</Typography>
            <Typography variant="body2">
              {addHyphensIntoSortCode(sortCode)}
            </Typography>
          </Stack>
        </Stack>
      </BoxWithGreyBorder>
    );
  }
};

export default BankDetailsCard;
