import { DIRECT_DEBIT_INSTRUCTION_STATUS } from '@/consts/directDebit';

import { title, fullName, supplyAddress as address } from './mockData';

export const fullNameWithMeter = {
  viewer: {
    title,
    fullName,
  },
  account: {
    properties: [
      {
        address,
        activeWaterMeters: [
          {
            id: '0',
          },
        ],
      },
    ],
  },
};

export const withoutMeter = {
  account: {
    properties: [
      {
        address,
        activeWaterMeters: [],
      },
    ],
  },
};

export const movedOut = {
  account: {
    properties: [],
  },
};

export const hasCurrentDirectDebitInstruction = {
  account: {
    directDebitInstructions: {
      edges: [
        {
          node: {
            id: '0',
            status: DIRECT_DEBIT_INSTRUCTION_STATUS.ACTIVE,
          },
        },
      ],
    },
  },
};

export const hasFailedDirectDebitInstruction = {
  account: {
    directDebitInstructions: {
      edges: [
        {
          node: {
            id: '0',
            status: DIRECT_DEBIT_INSTRUCTION_STATUS.FAILED,
          },
        },
      ],
    },
  },
};

export const noCurrentDirectDebitInstruction = {
  account: {
    directDebitInstructions: {
      edges: [],
    },
  },
};
