import { Alert, Typography, useCoralTheme } from '@krakentech/coral';
import { IconInfo } from '@krakentech/icons';
import { addDays, isFuture } from 'date-fns';

import { PAYMENT_FREQUENCY } from '@/consts/paymentFrequency';
import { useCurrentActivePaymentSchedule } from '@/hooks/billsAndPayments/useCurrentActivePaymentSchedule';
import { useUpcomingPaymentSchedule } from '@/hooks/billsAndPayments/useUpcomingPaymentSchedule';
import { formatCurrency } from '@/utils/formatters/currency';
import { formatFullDateShortMonth } from '@/utils/formatters/date';
import { cardinalNumberToOrdinalNumber } from '@/utils/formatters/number';
import { getPaymentFrequency } from '@/utils/paymentSchedule';

const UpcomingPaymentSchedule = () => {
  const {
    isLoading: isLoadingCurrentPaymentSchedule,
    error: isErrorCurrentPaymentSchedule,
    data: currentPaymentSchedule,
  } = useCurrentActivePaymentSchedule();

  const {
    isLoading: isLoadingUpcomingPaymentSchedule,
    error: isErrorUpcomingPaymentSchedule,
    data: upcomingPaymentSchedule,
  } = useUpcomingPaymentSchedule({
    activeOnDate: addDays(new Date(currentPaymentSchedule?.node?.validTo), 1),
  });
  const {
    color: {
      action: { info },
    },
  } = useCoralTheme();

  if (isLoadingCurrentPaymentSchedule || isLoadingUpcomingPaymentSchedule) {
    return null;
  }

  if (isErrorCurrentPaymentSchedule || isErrorUpcomingPaymentSchedule) {
    return null;
  }

  // Don't show anything if the user either doens't have an upcoming payment schedule or if their upcoming payment schedule
  if (!upcomingPaymentSchedule?.node) {
    return null;
  }

  const {
    paymentAmount,
    validFrom,
    paymentFrequency,
    paymentFrequencyMultiplier,
    isVariablePaymentAmount,
    paymentDay,
  } = upcomingPaymentSchedule.node;

  // Don't show anything if the "upcoming payment schedule" has already started. This can happen for customers whose payment schedule has an end date, but there's no payment schedule afterwards
  if (!isFuture(validFrom)) {
    return null;
  }

  return (
    <Alert
      severity="info"
      dataAttributes={{ 'data-testid': 'upcomingPaymentSchedule' }}
      icon={<IconInfo color={info} size={20} />}
    >
      <Typography>
        After <strong>{formatFullDateShortMonth(validFrom)}</strong>,
        you&apos;ll be paying{' '}
        {isVariablePaymentAmount ? (
          <strong>when you get your bill</strong>
        ) : (
          <strong>
            {formatCurrency(paymentAmount)} every{' '}
            {getPaymentFrequency(paymentFrequency, paymentFrequencyMultiplier)}
            {paymentFrequency === PAYMENT_FREQUENCY.MONTHLY &&
              ` on the ${cardinalNumberToOrdinalNumber(paymentDay)}`}
          </strong>
        )}
      </Typography>
    </Alert>
  );
};

export default UpcomingPaymentSchedule;
