import styled, { css } from 'styled-components';

export const StyledActionCard = styled.div<{
  addButtonStyle?: boolean;
  addRowGap?: boolean;
}>`
  ${({ theme, addButtonStyle, addRowGap }) => css`
    > div {
      a,
      button {
        padding: ${theme.spacing.lg} ${theme.spacing.mdLg};
        border: ${theme.overrides.card.root.borderWidth} solid transparent;

        &:hover,
        &:focus-visible {
          background-color: transparent;
        }
      }

      a {
        &:hover,
        &:focus-visible {
          border: ${theme.overrides.card.root.borderWidth} solid
            ${theme.color.primary.dark};
        }

        div {
          row-gap: ${addRowGap ? theme.spacing.smMd : 0};
        }
      }

      button {
        cursor: default;

        &:hover,
        &:focus {
          border-color: transparent;
        }

        &:focus-visible {
          border: ${theme.overrides.card.root.borderWidth} solid
            ${theme.color.primary.dark};
        }

        ${addButtonStyle &&
        css`
          &:hover,
          &:focus-visible {
            cursor: pointer;
            border: ${theme.overrides.card.root.borderWidth} solid
              ${theme.color.primary.dark};
          }
        `}

        div {
          row-gap: ${addRowGap ? theme.spacing.smMd : 0};
        }
      }

      svg {
        vertical-align: middle;
      }
    }
  `}
`;

export const StyledChevronRightIcon = styled.div<{ displayIcon?: boolean }>`
  ${({ displayIcon }) => css`
    display: ${displayIcon ? 'block' : 'none'};
  `}
`;
