import { ChangeEventHandler, MouseEventHandler, useCallback } from 'react';
import { Typography, Stack, useCoralBreakpoints } from '@krakentech/coral';
import { FieldProps } from 'formik';

import {
  StyledRadioCardInput,
  StyledRadioCard,
  StyledRadioCardContent,
  StyledRadioPresentation,
} from './index.styled';
import { RadioCardProps } from './index.types';

const RadioCard: React.FC<RadioCardProps & FieldProps> = ({
  value,
  label,
  explanation,
  form,
  field,
  onChange: onChangeCallback,
  onClick: onClickCallback,
  disabled,
  dataTestId,
}) => {
  const { isMaxSm } = useCoralBreakpoints();

  const isChecked = field.value === value;
  const uniqueId = `${field.name}${value}`;

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onChangeCallback?.(e);
      form.setFieldValue(field.name, value, true);
    },
    [field, form, value, onChangeCallback]
  );

  const onClick: MouseEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onClickCallback?.(e);
    },
    [onClickCallback]
  );

  return (
    <StyledRadioCard
      isChecked={isChecked}
      disabled={disabled}
      data-testid={dataTestId}
    >
      <StyledRadioCardInput
        type="radio"
        name={field.name}
        id={uniqueId}
        value={value}
        onChange={onChange}
        onClick={onClick}
        checked={isChecked}
        disabled={disabled}
      />
      <StyledRadioCardContent>
        <Stack alignItems="normal" gap={isMaxSm ? 'sm' : 'sm'}>
          <StyledRadioPresentation isChecked={isChecked} disabled={disabled} />

          <Stack direction="vertical" gap="xxs">
            <Typography component="label" html-for={uniqueId}>
              {label}
            </Typography>
            {explanation && (
              <Typography variant="body2">{explanation}</Typography>
            )}
          </Stack>
        </Stack>
      </StyledRadioCardContent>
    </StyledRadioCard>
  );
};

export default RadioCard;
