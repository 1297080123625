import { ReactNode } from 'react';
import Link from 'next/link';
import { Button } from '@krakentech/coral';

const BackButton = ({
  href,
  children,
}: {
  href: string;
  children: ReactNode;
}) => (
  <Link href={href} passHref legacyBehavior>
    <Button variant="ghost" color="tertiary" fullWidth size="medium">
      {children}
    </Button>
  </Link>
);

export default BackButton;
